import { ApiClient } from "../helpers/api-client";
import config from "../../public/config.json";


const apiClient = new ApiClient();

const sikayet_kaydet = async (soru_1, soru_2, yine_bekleriz, yorumlar) => {
  return await apiClient.makeRequest({
    url: `${config.apiUrl}/api/sikayets`,
    method: "POST",
    json: {
      data: {
        soru_1: soru_1,
        soru_2: soru_2,
        soru_3: 2,
        yine_bekleriz: yine_bekleriz,
        yorumlar: yorumlar,
      },
    },
  });
};
export { sikayet_kaydet };
