<template>
  <v-container>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        Ürünlerimizi Nasıl Buldunuz?
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="grey lighten-1" class="mb-12" height="45px" width="200"
          ><div class="text-center">
            <v-rating
              v-model="soru1_rating"
              hover
              half-increments
            ></v-rating></div
        ></v-card>
        <v-btn color="primary" @click="e6 = 2"> Sonraki </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        Hijyeni nasıl değerlendirirsiniz?
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card color="grey lighten-1" class="mb-12" height="45px" width="200"
          ><div class="text-center">
            <v-rating
              v-model="soru2_rating"
              hover
              half-increments
            ></v-rating></div
        ></v-card>
        <v-btn color="primary" @click="e6 = 3"> Sonraki </v-btn>
        <v-btn class="ml-3" color="primary" @click="e6 = 1"> Önceki </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Tekrar ziyaret etmeyi düşünür müsünüz?
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-select
          :items="items"
          v-model="value"
          @change="degisti"
          solo
        ></v-select>
        <div class="text-center"></div>
        <v-btn color="primary" @click="e6 = 4"> Sonraki </v-btn>
        <v-btn class="ml-3" color="primary" @click="e6 = 2"> Önceki </v-btn>
      </v-stepper-content>

      <v-stepper-step step="4">
        Yorumunuz bizim için çok değerlidir
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-textarea
          v-model="yorumlar"
          class="mt-5"
          label="Yorum"
          background-color="black"
          auto-grow
          outlined
          rows="1"
          row-height="15"
        ></v-textarea>
        <v-btn color="primary" @click="tamam"> Tamam </v-btn>
        <v-btn class="ml-3" color="primary" @click="e6 = 3"> Önceki </v-btn>
      </v-stepper-content>
    </v-stepper>
    <v-snackbar v-model="snackbar" timeout="2000">Başarılı</v-snackbar>
  </v-container>
</template>

<script>
import { sikayet_kaydet } from "../query/sikayet";
export default {
  data() {
    return {
      items: ["EVET", "HAYIR"],
      value: "EVET",
      e6: 1,
      model: true,
      soru1_rating: 2,
      soru2_rating: 2,
      yorumlar: null,
      yine_bekleriz: "EVET",
      snackbar: false,
    };
  },
  methods: {
    async tamam() {
      const sonuc = await sikayet_kaydet(
        this.soru1_rating,
        this.soru2_rating,
        this.yine_bekleriz,
        this.yorumlar
      );
      if (sonuc.data.id != null) {
        this.$swal("Kayıt Başarılı");
        this.$router.push({
          name: "Home",
        });
      }
      this.snackbar = true;
    },
    degisti(i) {
      this.yine_bekleriz = i;
    },
  },
};
</script>
